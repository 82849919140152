import { Profile404 } from 'containers/Errors/Profile404';
import Profile from 'containers/ProfilePage/ProfilePage';
import { ProfilePartsFragment } from 'generated/graphql';
import { NextPage, NextPageContext } from 'next';
import { getProfileByHandle } from 'utils/ssrDataService';

type Props = {
  profile: ProfilePartsFragment | null;
};

const ProfilePage: NextPage<Props> = ({ profile }) => {
  if (!profile) return <Profile404 />;

  return <Profile profile={profile} />;
};

export async function getServerSideProps({ query, res }: NextPageContext): Promise<{ props: Props }> {
  const handle = query.handle as string;
  try {
    const profile = await getProfileByHandle(handle);
    if (res && !profile) res.statusCode = 404;
    return { props: { profile } };
  } catch (error) {
    if (res) res.statusCode = 404;
    return { props: { profile: null } };
  }
}

export default ProfilePage;
